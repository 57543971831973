<template>
  <div class="userList">
    <h1>后台用户管理</h1>
    <el-form :inline="true">
      <el-form-item label="电话">
        <el-input style="width: 120px;" placeholder="请输入完整电话" v-model="searchParam.phone" clearable />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新增</el-button>
          <el-button type="warning" :disabled="show.selected.length != 1" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="show.selected.length != 1 || !show.selected[0].hasUsing" @click="setUsing(false)">禁用</el-button>
          <el-button type="success" :disabled="show.selected.length != 1 || show.selected[0].hasUsing" @click="setUsing(true)">启用</el-button>
          <el-button type="info" :disabled="show.selected.length != 1" @click="resetPassword()">重置密码</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.userList" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="nick" label="姓名、昵称" width="200" show-overflow-tooltip>
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">{{ scope.row.nick }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="200" />
      <el-table-column prop="admin" label="特殊身份" width="100">
        <template #default="scope">
          <el-tag v-if="scope.row.admin">超管</el-tag>
          <el-text v-else></el-text>
        </template>
      </el-table-column>
      <el-table-column prop="hasUsing" label="状态" width="100">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.hasUsing">启用</el-tag>
          <el-tag type="danger" v-if="!scope.row.hasUsing">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="角色" width="200" show-overflow-tooltip />
      <el-table-column prop="createDatetime" label="创建时间" />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.userTotal" @change="commitFindList()" />
  </div>

  <el-dialog v-model="show.resetPasswordShow" title="新密码：" width="550" :close-on-click-modal="false" :close-on-press-escape="false">
    <p>
      <el-text>为了您的密码安全，</el-text>
      <el-text size="large"><b>新密码只展示一次</b></el-text>
      <el-text>，请注意保管。请将密码保存在本地，不要保存在网盘、Git，请不要在聊天软件、网络社区平台转发分享。</el-text>
    </p>
    <p>
      <el-link type="primary" @click="util.clipBoard(show.resetPasswordValue)">
        {{ show.resetPasswordValue }}
      </el-link>
    </p>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="show.resetPasswordShow = false">好的</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  userTotal: 0,
  userList: [],
  menuList: [],
})

const show = reactive({
  resetPasswordShow: false,
  resetPasswordValue: '',
  selected: [],
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.phone = ''
  searchParam.using = null
  searchParam.roleId = null
}
resetSearchParam()

const commitFindList = () => {
  api.get('/backend/user/getPage', { params: searchParam }).then(res => {
    data.userList = res.list
    data.userTotal = res.total
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'userDetail', null)
  router.push('/userDetail')
}

const update = (row) => {
  const user = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'userDetail', user)
  router.push('/userDetail')
}

const setUsing = (hasUsing) => {
  const user = tabRef.value.getSelectionRows()[0]
  const messageName = hasUsing ? '启用' : '禁用'
  ElMessageBox.confirm('', {
    title: '确认' + messageName + '：',
    message: '将要' + messageName + ' ' + user.nick + ' 。',
    autofocus: false,
    confirmButtonText: messageName,
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/user/setUsingById', { id: user.id, using: hasUsing }).then(() => {
      ElMessage.success(messageName + '成功')
      commitFindList()
    })
  })
}

const resetPassword = () => {
  const user = tabRef.value.getSelectionRows()[0]
  ElMessageBox.confirm('', {
    title: '确认重置密码',
    message: '将要重置 ' + user.nick + ' 的密码。',
    autofocus: false,
    confirmButtonText: '重置',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/user/resetPasswordById', { id: user.id }).then(res => {
      ElMessage.success('重置密码成功')
      setTimeout(() => {
        show.resetPasswordValue = res.password
        show.resetPasswordShow = true
      }, 1000)
    })
  })
}

</script>

<style lang="less"></style>